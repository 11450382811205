/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import emotionStyled from '@emotion/styled';
import { useTheme as useThemeBasic } from '@emotion/react';
import createCache from '@emotion/cache';

export {
  css,
  keyframes,
  jsx,
  ThemeProvider,
  CacheProvider as EmotionCacheProvider,
  withTheme,
} from '@emotion/react';
export { default as createEmotionCache } from '@emotion/cache';

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends SupersetTheme {}
}

export function useTheme() {
  const theme = useThemeBasic();
  // in the case there is no theme, useTheme returns an empty object
  if (Object.keys(theme).length === 0 && theme.constructor === Object) {
    throw new Error(
      'useTheme() could not find a ThemeContext. The <ThemeProvider/> component is likely missing from the app.',
    );
  }
  return theme;
}

export const emotionCache = createCache({
  key: 'superset',
});

export const styled = emotionStyled;

const defaultTheme = {
  borderRadius: 4,
  colors: {
    text: {
      label: '#171928',
      help: '#262940',
    },
    primary: {
      base: '#5440F7',
      dark1: '#2B33E7',
      dark2: '#0021D9',
      light1: '#B5A3FB',
      light2: '#D3C8FC',
      light3: '#EEE8FE',
      light4: '#EEE8FE',
      light5: '#F6EEFF',
    },
    secondary: {
      base: '#6F738C',
      dark1: '#5b5f77',
      dark2: '#3C4057',
      dark3: '#1A1F34',
      light1: '#989CB6',
      light2: '#B7BBD6',
      light3: '#CECED9',
      light4: '#E8ECFF',
      light5: '#F0F3FF',
    },
    grayscale: {
      base: '#666666',
      dark1: '#323232',
      dark2: '#000000',
      light1: '#B2B2B2',
      light2: '#E0E0E0',
      light3: '#F0F0F0',
      light4: '#F7F7F7',
      light5: '#FFFFFF',
    },
    error: {
      base: '#DD2727',
      dark1: '#D01E1F',
      dark2: '#af060e',
      light1: '#F69A98',
      light2: '#FFEFF0',
    },
    warning: {
      base: '#FFAD0D',
      dark1: '#fe8b0f',
      dark2: '#fd6b0f',
      light1: '#ffd150',
      light2: '#FFFBF3',
    },
    alert: {
      base: '#FFAD0D',
      dark1: '#fe8b0f',
      dark2: '#fd6b0f',
      light1: '#ffd150',
      light2: '#FFFBF3',
    },
    success: {
      base: '#0BB07B',
      dark1: '#00945b',
      dark2: '#007142',
      light1: '#B5D3D3',
      light2: '#e2f4ec',
    },
    info: {
      base: '#205CFA',
      dark1: '#0036ea',
      dark2: '#001dd4',
      light1: '#94a1fb',
      light2: '#F0FFFE',
    },
  },
  opacity: {
    light: '10%',
    mediumLight: '35%',
    mediumHeavy: '60%',
    heavy: '80%',
  },
  typography: {
    families: {
      sansSerif: `'Rubik', 'Inter', Helvetica, Arial`,
      serif: `Georgia, 'Times New Roman', Times, serif`,
      monospace: `'Fira Code', 'Courier New', monospace`,
    },
    weights: {
      light: 200,
      normal: 400,
      medium: 500,
      bold: 600,
    },
    sizes: {
      xxs: 9,
      xs: 10,
      s: 12,
      m: 14,
      l: 16,
      xl: 21,
      xxl: 28,
    },
  },
  zIndex: {
    aboveDashboardCharts: 10,
    dropdown: 11,
    max: 3000,
  },
  transitionTiming: 0.3,
  gridUnit: 4,
  brandIconMaxWidth: 37,
};

export type SupersetTheme = typeof defaultTheme;

export interface SupersetThemeProps {
  theme: SupersetTheme;
}

export const supersetTheme = defaultTheme;
